<template>
  <v-card
          class="banknote d-flex"
          :color="color"
  >
      <p class="price">{{price}} $</p>
  </v-card>
</template>

<script>
export default {
    name: "BanknoteComponent",
    props: {
        price: Number,
        color: String
    }
}
</script>

<style scoped>
.banknote {
    --size: 100px;
    height: var(--size);
    aspect-ratio: 7 / 4;
    border: 5px solid #f5f7c5;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
}
.price {
    font-size: calc(var(--size) / 3);
    margin: 0;
    display: inline-block;
}
</style>