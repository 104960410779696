<template>
  <div>
      <div id="option-icon">
          <v-icon
              @click="update_dialog(!dialog)"
              icon="mdi-cog"
          ></v-icon>
          <RulesComponent></RulesComponent>
      </div>
      <v-dialog
              :model-value="dialog"
              persistent
              :max-width="500"
              transition="dialog-bottom-transition"
              class="mb-10"
      >
          <v-card class="pa-5">
              <v-card-title>Option menu</v-card-title>
              <v-card-text v-if="game?.code">
                  Code : {{ game.code }}
              </v-card-text>
              <v-card-text v-if="game?.list_player?.length">
                  Players
                  <div
                      v-for="(player, index) in game.list_player"
                      :key="index"
                      :style="`color: ${player.color}`"
                  >
                      {{player.pseudo}}
                  </div>
              </v-card-text>
              <div v-if="!in_game">
                  <v-card-actions>
                      <v-autocomplete
                          @change="update_code_input"
                          v-model="code_input"
                          :items="[...codes, code_input]"
                          :readonly="in_game"
                          variant="solo"
                          label="Enter your code"
                          :maxlength="10"
                          counter
                          persistent-counter
                      >
                          <template v-slot:append-inner>
                              <ReloadCodeComponent @update_codes="update_codes"></ReloadCodeComponent>
                          </template>
                      </v-autocomplete>
                  </v-card-actions>
                  <v-card-actions>
                      <v-text-field
                          v-model="pseudo"
                          :readonly="in_game"
                          :color="color"
                          variant="solo"
                          label="Enter your pseudo"
                          :maxlength="10"
                          counter
                          persistent-counter
                      ></v-text-field>
                  </v-card-actions>
                  <v-card-actions>
                      <v-color-picker
                          v-model="color"
                          class="mx-auto"
                          hide-canvas
                          hide-inputs
                          hide-sliders
                          show-swatches
                          :swatches="swatches"
                      ></v-color-picker>
                  </v-card-actions>
              </div>
              <div v-if="is_admin">
                  <v-card-actions>
                      <v-text-field
                          v-model="bot_number"
                          variant="solo"
                          label="Number of bot"
                          type="number"
                          :min="0"
                          :max="4"
                      ></v-text-field>
                      <v-checkbox
                          v-model="is_public"
                          label="Public game"
                          color="blue"
                      ></v-checkbox>
                  </v-card-actions>
                  <v-card-actions v-if="(game?.players?.length ?? 0) >= 2">
                      <v-select
                          v-model="pseudo_kick"
                          :items="game.players"
                          item-title="pseudo"
                          item-value="pseudo"
                          variant="solo"
                          label="Enter your pseudo kick"
                          clearable
                      ></v-select>
                  </v-card-actions>
                  <v-card-actions v-if="pseudo_kick">
                      <v-btn
                          @click="kick_player"
                          :disabled="!pseudo_kick"
                          block
                          text="Kick"
                          color="orange"
                          variant="tonal"
                          append-icon="mdi-account-off-outline"
                      ></v-btn>
                  </v-card-actions>
                  <v-card-actions>
                      <v-btn
                          @click="launch_game"
                          :disabled="can_launch"
                          block
                          text="Launch"
                          color="green"
                          variant="tonal"
                          append-icon="mdi-play-outline"
                      ></v-btn>
                  </v-card-actions>
              </div>
              <v-card-actions v-if="in_game">
                  <v-btn
                      @click="leave_game"
                      block
                      text="Leave"
                      color="red"
                      variant="tonal"
                      append-icon="mdi-exit-run"
                  ></v-btn>
              </v-card-actions>
              <div v-if="!in_game">
                  <v-card-actions>
                      <v-btn
                          @click="create_game"
                          :disabled="!code_input"
                          block
                          text="Create"
                          color="blue"
                          variant="tonal"
                          append-icon="mdi-plus-box-outline"
                      ></v-btn>
                  </v-card-actions>
                  <v-card-actions>
                      <v-btn
                          @click="join_game"
                          :disabled="!code_input || !pseudo || !color"
                          block
                          text="Join"
                          color="blue"
                          variant="tonal"
                          append-icon="mdi-location-enter"
                      ></v-btn>
                  </v-card-actions>
              </div>
              <v-card-actions v-if="game?.status">
                  <v-btn
                      @click="update_dialog(false)"
                      block
                      text="Close"
                      color="red"
                      variant="tonal"
                      append-icon="mdi-close-circle-outline"
                  ></v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import RulesComponent from "@/components/RulesComponent.vue";
import ReloadCodeComponent from "@/components/ReloadCodeComponent.vue";

export default {
    name: "MenuComponent",
    components: {ReloadCodeComponent, RulesComponent},
    props: {
        dialog: Boolean
    },
    data: () => ({
        codes: [],
        code_input: "",
        pseudo: "",
        pseudo_kick: "",
        color: "",
        swatches: [
            ["#bb7000"],
            ["#a40000"],
            ["#009d00"],
            ["#0000c2"],
            ["#940094"],
        ],
        bot_number: 0,
        is_public: true
    }),
    computed: {
        ...mapState(["game", "code", "player"]),
        is_admin() {
            if (!this.game || !this.player?.pseudo) return false
            return this.game?.list_player?.at(0)?.pseudo === this.player?.pseudo
        },
        in_game() {
            return !!this.game?.players?.find(player => player.pseudo === this.player?.pseudo && player.color === this.player?.color)
        },
        can_launch() {
            if (this.game?.status) return true
            if (this.bot_number < 0) return true
            const player_number = (this.game?.players?.length ?? 0)+(parseInt(this.bot_number)||0)
            return player_number < 2 || player_number > 5
        }
    },
    methods: {
        ...mapMutations(["update_player"]),
        create_game() {
            this.$emit("create_game", this.code_input)
        },
        join_game() {
            if (!this.pseudo || !this.color || !this.code_input) return
            this.update_player({
                pseudo: this.pseudo,
                color: this.color
            })
            this.$emit("join_game", this.code_input)
        },
        leave_game() {
            this.$emit("leave_game")
        },
        launch_game() {
            this.$emit("launch_game", this.bot_number, this.is_public)
        },
        kick_player() {
            this.$emit("kick_player", this.pseudo_kick)
            this.pseudo_kick = ""
        },
        update_dialog(dialog) {
            if (this.game?.status) this.$emit("update_dialog", dialog)
        },
        update_codes(codes) {
            this.codes = codes
        },
        update_code_input(e) {
            const code = e?.target?.value
            if (code) this.code_input = code
        }
    },
    mounted() {
        const code = new URL(document.location).searchParams.get("code")
        if (code) this.code_input = code
        let player = localStorage.getItem("player")
        if (!player) return
        try {
            player = JSON.parse(player)
            if (player?.pseudo) this.pseudo = player.pseudo
            if (player?.color) this.color = player.color
        } catch (e) {
            console.error(e)
        }

    }
}
</script>

<style scoped>
#option-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    display: grid;
    grid-gap: 20px;
    z-index: 2500;
}
</style>