<template>
    <div>
        <v-snackbar
            :model-value="!!error_message || !!update_message"
            timeout="-1"
            location="bottom"
        > <!--todo model-value-->
            {{ error_message }} / {{ update_message }}
        </v-snackbar>
        <MenuComponent
            :dialog="dialog"
            @update_dialog="update_dialog"
            @create_game="create_game"
            @join_game="join_game"
            @leave_game="leave_game"
            @launch_game="launch_game"
            @kick_player="kick_player"
        ></MenuComponent>
        <div v-if="game?.status">
            <LeaderboardComponent :dialog="dialog_leaderboard"></LeaderboardComponent>
            <BoardComponent @place_dices="place_dices"></BoardComponent>
        </div>
    </div>
</template>



<script>
import io from "socket.io-client";
import {defineComponent} from 'vue';
import {mapMutations, mapState} from "vuex";
import MenuComponent from "@/components/MenuComponent.vue";
import BoardComponent from "@/components/BoardComponent.vue";
import router from "@/router";
import LeaderboardComponent from "@/components/LeaderboardComponent.vue";

export default defineComponent({
    name: 'GameBoardView',
    components: {LeaderboardComponent, BoardComponent, MenuComponent},
    data: () => ({
        socket: null,
        number: null,
        dialog: true,
        dialog_leaderboard: false
    }),
    computed: {
        ...mapState(["game", "code", "player", "error_message", "update_message"])
    },
    methods: {
        ...mapMutations(["update_game", "update_code", "set_message" , "update_player", "update_error_message"]),
        create_game(code) {
            this.socket.emit("create_game", code, (game) => {
                this.update_code(game)
                if (game.code && !game.error) {
                    router.push("?code=" + game.code)
                    this.update_game([game, "You have create the game"])
                } else {
                    router.push("/")
                }
            })
        },
        join_game(code) {
            this.socket.emit("join_game", code, this.player, (game) => {
                if (game?.error) {
                    this.update_error_message(game)
                    router.push("/")
                } else {
                    this.update_code(game)
                    router.push("?code="+game.code)
                }
            })
        },
        leave_game() {
            this.socket.emit("leave_game", this.code, this.player, this.clear_game)
        },
        launch_game(bot_number, is_public) {
            this.socket.emit("launch_game", this.code, this.player, bot_number, is_public, this.update_error_message)
        },
        place_dices(number) {
            this.socket.emit("place_dices", this.code, this.player, number, this.update_error_message)
        },
        kick_player(pseudo_kick) {
            this.socket.emit("kick_player", this.code, this.player, pseudo_kick, this.update_error_message)
        },
        on_kick(data) {
            const game = data.at(0) ?? {}
            if (game?.code !== this.code) return
            const pseudo = data.at(0)
            if (this.player?.pseudo === pseudo) this.clear_game(null)
        },
        clear_game() {
            this.update_code(null)
            this.update_game([{}, "You leave the game."])
            router.push("/")
        },
        update_dialog(dialog) {
            this.dialog = dialog
        }
    },
    async mounted() {
        const socket_url = process.env.NODE_ENV === 'production' ? "las-vegas.nexford.fr" : "http://localhost:3000"
        this.socket = io.connect(socket_url);
        this.socket.on("update_game", this.update_game)
        this.socket.on("launch_game", (data) => {
            const game = data.at(0) ?? {}
            if (game?.code !== this.code) return
            this.update_dialog(false)
        }) // todo animate
        this.socket.on("place_dices", () => {}) // todo animate
        this.socket.on("next_turn", () => {}) // todo animate
        this.socket.on("end_round", (data) => {
            const game = data.at(0) ?? {}
            if (game?.code !== this.code) return
            this.dialog_leaderboard = true
            setTimeout(() => {
                this.dialog_leaderboard = false
            }, 5000)
        }) // todo animate
        this.socket.on("end_game", (data) => {
            const game = data.at(0) ?? {}
            if (game?.code !== this.code) return
            this.dialog_leaderboard = true
        }) // todo animate
        this.socket.on("kick", this.on_kick) // todo animate
        const code = new URL(document.location).searchParams.get("code")
        if (code) this.socket.emit("get_game_info", code, this.update_game)
    },
    beforeUnmount() {
        this.socket.disconnect();
    }
});
</script>
