import {createStore} from 'vuex'

export default createStore({
  state: {
    game: {},
    code: null,
    player: null,
    update_message: null,
    error_message: null
  },
  getters: {},
  mutations: {
    update_game(state, data) {
      const game = data.at(0) ?? {}
      if (game?.code !== state.code) return
      const message = data.at(1)
      if (process.env.NODE_ENV !== 'production') console.log(game)
      state.game = game
      state.update_message = message
    },
    update_code(state, game) {
      state.error_message = null
      if (game?.error) {
        state.error_message = game.error
      }
      state.code = game?.code
    },
    update_player(state, player) {
      state.player = player
      if (player?.pseudo && player?.color) {
        localStorage.setItem("player", JSON.stringify({
          pseudo: player.pseudo,
          color: player.color
        }))
      }
    },
    set_message(state, data) {
      const game = data.at(0) ?? {}
      if (game?.code !== state.code) return
      state.update_message = data.at(1)
    },
    update_error_message(state, data) {
      state.error_message = data?.error
    }
  },
  actions: {},
  modules: {}
})
