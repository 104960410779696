<template>
    <a target="_blank" :href="pdf_rules_link">
        <v-icon
            id="rules"
            icon="mdi-script-text-outline"
            color="blue"
        >
        </v-icon>
    </a>
</template>

<script>
export default {
    name: "RulesComponent",
    data: () => ({
        pdf_rules_link: "https://link.nexford.fr/1693497321010" // todo pdf file link
    })
}
</script>

<style scoped>

</style>