<template>
    <v-btn
        @click="reload_code"
        :disabled="loading"
        :loading="loading"
        icon="mdi-reload"
    ></v-btn>
</template>

<script>
import io from "socket.io-client";
export default {
    name: "ReloadCodeComponent",
    data: () => ({
        socket: null,
        loading: false
    }),
    methods: {
        reload_code() {
            this.loading = true
            this.socket.emit("reload_code", (codes) => {
                console.log({codes})
                this.loading = false
                this.$emit("update_codes", codes)
            })
        }
    },
    async mounted() {
        const socket_url = process.env.NODE_ENV === 'production' ? "las-vegas.nexford.fr" : "http://localhost:3000"
        this.socket = io.connect(socket_url);
        this.reload_code()
    },
    beforeUnmount() {
        this.socket.disconnect();
    }
}
</script>

<style scoped>

</style>