<template>
    <v-card
            :color="color"
            class="dice"
    >
        <v-icon
            :icon="`mdi-dice-${number}-outline`"
        ></v-icon>
    </v-card>
</template>

<script>
export default {
    name: "DiceComponent",
    props: {
        number: Number,
        color: String
    }
}
</script>

<style scoped>
.dice {
    aspect-ratio: 1;
    border-radius: 5px;
    border: 1px solid;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>