<template>
  <div class="user-select-none">
      <div>
          <div
                  v-for="(board, index) in get_boards"
                  :key="index"
                  class="d-flex"
          >
              <div class="board">
                  <DiceComponent
                          v-for="(dice, index2) in board.dices"
                          :key="index2"
                          :number="dice.number"
                          :color="dice.color"
                  ></DiceComponent>
              </div>
              <div class="d-flex">
                  <BanknoteComponent
                          v-for="(banknote, index2) in board.banknotes"
                          :key="index2"
                          :price="banknote.price"
                          :color="banknote.color"
                  ></BanknoteComponent>
              </div>
          </div>
      </div>
      <div class="dice-roll bg-grey-lighten-1">
          <div
              @click="place_dices(number)"
              v-for="number in 6"
              :key="number"
              class="dice-line"
          >
              <DiceComponent
                  v-for="(dice, index) in get_dices.filter(d => d.number === number)"
                  :key="index"
                  :number="dice.number"
                  :color="dice.color"
              ></DiceComponent>
          </div>

      </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import BanknoteComponent from "@/components/BanknoteComponent.vue";
import DiceComponent from "@/components/DiceComponent.vue";

export default {
    name: "BoardComponent",
    components: {DiceComponent, BanknoteComponent},
    computed: {
        ...mapState(["game"]),
        get_dices() {
            if (this.game?.players?.find(player=>player.pseudo === this.game?.status?.turn)?.dices.length) return this.game?.players?.at(0)?.dices
            return []
        },
        get_boards() {
            if (this.game?.boards?.length) return this.game?.boards
            return []
        }
    },
    methods: {
        place_dices(number) {
            this.$emit("place_dices", number)
        }
    }
}
</script>

<style scoped>
.user-select-none {
    user-select: none;
}

.board {
    --size: 100px;
    height: var(--size);
    padding: 5px;
    aspect-ratio: 2 / 1;
    background-image: url('https://link.nexford.fr/1693380059633');
    background-size: cover;
    background-position: center;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    margin-bottom: auto;
}

.dice-roll {
    margin: 20px;
    padding: 20px;
    gap: 20px;
}

.dice-line {
    display: flex;
    height: 20px;
    gap: 2px;
    margin: 10px 0;
}
</style>