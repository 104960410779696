<template>
  <v-dialog
          v-if="game?.players?.length"
          :model-value="dialog"
          persistent
          :max-width="500"
          transition="dialog-bottom-transition"
          class="mb-10"
  >
      <v-card class="pa-5">
          <v-list>
              <v-list-subheader>Leaderboard</v-list-subheader>

              <v-list-item
                      v-for="(player, index) in game.players"
                      :key="index"
                      :value="player"
                      :style="`color: ${player.color}`"
                      :prepend-icon="`mdi-numeric-${index+1}-circle-outline`"
              >
                  <v-list-item-title>
                      {{player.pseudo}} : {{player.total}}$
                  </v-list-item-title>
              </v-list-item>
          </v-list>
      </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "LeaderboardComponent",
    props: {
        dialog: Boolean
    },
    computed: {
        ...mapState(["game"])
    }
}
</script>

<style scoped>

</style>